import React, { useEffect } from 'react';

//material ui
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import CircularProgress from '@material-ui/core/CircularProgress';

//containers
import PromoCode from '../PromoCode';

//third part libs
import { FormattedMessage, injectIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import messages from './messages';

//redux
import { IBillingUserInfo, IBillingFormError } from '../PaymentDetails/types';
import { getBrandNameSelector } from '../BrandProvider/selectors';
import { useSelector } from 'react-redux';

//global style
import '../../themes/common/billingInfoView.scss';
import cvvImage from '../../images/cvv.svg';

//components and json
import { getRecurlyUrl, getRecurlyPublicKey } from '../../env/config_util';
import us_states from './us_states.json';
import countries from './countries.json';
import Dropdown from '../../components/Dropdown';

//global recurly script
const $script = require('scriptjs');

//global recurly var that is in the script
declare var recurly: any;

interface Props {
  handleInputChange: Function;
  handleInputChangeCountry: Function;
  handleRecurlyEvent: Function;
  userInfo: IBillingUserInfo;
  formErrors: IBillingFormError;
  intl: any;
  onRecurlyFieldChange?: any;
  showPromoSection: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const BillingInformationView = (props: Props) => {
  const brand = useSelector(getBrandNameSelector);
  const isMobile = useMediaQuery({ query: `(max-width: 720px)` });
  const classes = useStyles();
  const { userInfo, formErrors } = props;
  const { formatMessage } = props.intl;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isMounted, setIsMounted] = React.useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    const handleRecurlyChange = (event: any) => {
      props.handleRecurlyEvent(event);
      if (props.onRecurlyFieldChange) {
        props.onRecurlyFieldChange();
      }
    };

    const recurlyUrl = getRecurlyUrl(brand);
    if (recurlyUrl) {
      $script(recurlyUrl, (): any => {
        recurly.configure({
          publicKey: getRecurlyPublicKey(brand),
          fields: {
            all: {
              style: {
                placeholder: {
                  color: '#9e9e9e',
                },
                fontSize: '1rem',
              },
            },
            month: {
              style: {
                placeholder: {
                  content: `${formatMessage(messages.monthPlaceHolder)}`,
                },
              },
            },
            year: {
              style: {
                placeholder: {
                  content: `${formatMessage(messages.yearPlaceHolder)}`,
                },
              },
            },
            cvv: {
              style: {
                placeholder: {
                  content: `${formatMessage(messages.cvvPlaceHolder)}`,
                },
              },
            },
          },
        });
        recurly.on('change', handleRecurlyChange);
      });
    }

    setIsMounted(true);
  }, [brand, formatMessage, props, props.handleRecurlyEvent, props.onRecurlyFieldChange]);

  const hasError = (field: string): boolean => {
    return field !== '';
  };

  const renderInputBoxWithLabel = (
    id: string,
    formError: any,
    label: any,
    maxLength: number,
    errorMsg: any,
    value: any,
  ) => {
    return (
      <div>
        <TextField
          id={id}
          label={
            <p
              style={{
                marginTop: -5,
                textTransform: 'uppercase',
                fontWeight: 'bolder',
                width: '100%',
              }}>
              <FormattedMessage {...label} />
            </p>
          }
          error={formError && formError !== '' ? true : false}
          style={{ margin: 8, width: '100%', fontSize: 16 }}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          value={value ? value : ''}
          className={`${brand}-text-field`}
          helperText={
            formError && formError !== '' ? (
              <span id={id + '_error'}>
                <FormattedMessage {...errorMsg} />
              </span>
            ) : null
          }
          onChange={e => props.handleInputChange(e)}
        />
      </div>
    );
  };

  const renderUsDropdownOrInput = () => {
    let response: any = '';
    if (userInfo.isCountryUs) {
      response = (
        <div style={{ width: '100%' }} className={`${brand}-text-field`}>
          <FormControl className={classes.formControl}>
            <InputLabel>
              {
                <p
                  className={hasError(formErrors.state) ? `${brand}-error-text` : ``}
                  style={{
                    marginTop: -5,
                    textTransform: 'uppercase',
                    fontWeight: 'bolder',
                  }}>
                  <FormattedMessage {...messages.stateLabel} />
                </p>
              }
            </InputLabel>
            <Dropdown
              className={`${brand}-select-drop-down`}
              id="state"
              onChange={props.handleInputChange}
              values={us_states}
              value={userInfo.state ? userInfo.state : 'select'}
              brand={brand}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          {hasError(formErrors.state) ? (
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              className={`${brand}-error-text`}
              style={{ marginLeft: 7, marginTop: 2 }}>
              <FormattedMessage {...messages.stateValidationError} />
            </Typography>
          ) : null}
        </div>
      );
    } else {
      response = renderInputBoxWithLabel(
        'state',
        formErrors.state,
        messages.stateInputLabel,
        50,
        messages.stateValidationError,
        userInfo.state,
      );
    }

    return response;
  };

  return (
    <>
      {!isMounted ? (
        <div className="progress-wrapper-center">
          <CircularProgress size={55} className={`${brand}-button-progress`} />
        </div>
      ) : (
        <Container maxWidth="md">
          <form className={classes.root} noValidate autoComplete="off">
            <div className="billing-info-body" style={{ fontSize: '1rem' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}>
                <div
                  className={`${brand}-text-primary`}
                  style={{
                    fontSize: '1.4rem',
                    marginBottom: 10,
                    marginLeft: 6,
                  }}>
                  <FormattedMessage {...messages.billingTitle} />
                </div>
                <div
                  style={{
                    marginBottom: 25,
                    width: '100%',
                  }}>
                  {renderInputBoxWithLabel(
                    'address1',
                    formErrors.address1,
                    messages.streetAddressLabel,
                    50,
                    messages.streetValidationError,
                    userInfo.address1,
                  )}
                </div>
                <div
                  style={{
                    marginBottom: 25,
                    width: '100%',
                    display: 'flex',
                  }}>
                  <div style={{ marginRight: 15, width: '100%' }}>
                    {renderInputBoxWithLabel(
                      'address2',
                      formErrors.address2,
                      messages.aptLabel,
                      50,
                      messages.streetValidationError,
                      userInfo.address2,
                    )}
                  </div>
                  <div style={{ width: '100%' }}>
                    {renderInputBoxWithLabel(
                      'city',
                      formErrors.city,
                      messages.cityLabel,
                      50,
                      messages.cityValidationError,
                      userInfo.city,
                    )}
                  </div>
                </div>
                <div
                  style={{
                    marginBottom: 25,
                    width: '100%',
                    display: 'flex',
                  }}>
                  <div style={{ marginRight: 15, width: '100%' }}>{renderUsDropdownOrInput()}</div>
                  <div style={{ width: '100%' }}>
                    {renderInputBoxWithLabel(
                      'postal_code',
                      formErrors.postal_code,
                      userInfo.isCountryUs ? messages.zipLabel : messages.postalCodeLabel,
                      50,
                      messages.zipValidationError,
                      userInfo.postal_code,
                    )}
                  </div>
                </div>
                <div style={{ width: '100%' }} className={`${brand}-text-field`}>
                  <FormControl className={classes.formControl}>
                    <InputLabel>
                      {
                        <p
                          style={{
                            marginTop: 4,
                            textTransform: 'uppercase',
                            fontWeight: 'bolder',
                          }}>
                          <FormattedMessage {...messages.countryLabel} />
                        </p>
                      }
                    </InputLabel>
                    <Dropdown
                      className={`${brand}-select-drop-down`}
                      id="country"
                      onChange={props.handleInputChangeCountry}
                      values={countries}
                      value={userInfo.country ? userInfo.country : 'US'}
                      brand={brand}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </div>
              </div>
              {!isMobile ? (
                <Divider orientation="vertical" flexItem style={{ marginLeft: 40, marginRight: 20, height: 500 }} />
              ) : null}
              {isMobile ? <div style={{ marginTop: 20 }} /> : null}

              <div className="credit-card-control">
                <div
                  className={`${brand}-text-primary`}
                  style={{
                    fontSize: '1.4rem',
                    marginBottom: 4,
                    marginLeft: 6,
                  }}>
                  <FormattedMessage {...messages.creditCardTitle} />
                </div>
                <Typography variant="caption" color="textSecondary" style={{ marginBottom: 10, fontStyle: 'italic' }}>
                  * Prepaid cards are not accepted.
                </Typography>
                <div
                  style={{
                    marginBottom: 25,
                    width: '100%',
                    display: 'flex',
                  }}>
                  <div style={{ marginRight: 15, width: '100%' }}>
                    {renderInputBoxWithLabel(
                      'first_name',
                      formErrors.first_name,
                      messages.firstNameLabel,
                      50,
                      messages.firstNameValidationError,
                      userInfo.first_name,
                    )}
                  </div>
                  <div style={{ width: '100%' }}>
                    {renderInputBoxWithLabel(
                      'last_name',
                      formErrors.last_name,
                      messages.lastNameLabel,
                      50,
                      messages.lastNameValidationError,
                      userInfo.last_name,
                    )}
                  </div>
                </div>
                <div
                  style={{
                    marginBottom: 25,
                    width: '100%',
                    marginLeft: 7,
                  }}>
                  <div
                    style={{
                      marginRight: 15,
                      width: '100%',
                      marginTop: 2,
                      borderBottom: '1px rgba(0, 0, 0, 0.5) solid',
                    }}>
                    <span className={hasError(formErrors.number) ? `${brand}-form-label-error` : `${brand}-form-label`}>
                      <FormattedMessage {...messages.creditCardLabel} />
                    </span>
                    <div data-recurly="number"></div>
                  </div>
                  {hasError(formErrors.number) ? (
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      className={`${brand}-error-text`}
                      id="card-number-error">
                      <FormattedMessage {...messages.numberValidationError} />
                    </Typography>
                  ) : null}
                </div>

                <div style={{ display: 'flex', marginTop: 10, marginLeft: 7 }}>
                  <div>
                    <div>
                      <span
                        className={
                          hasError(formErrors.month) || hasError(formErrors.year)
                            ? `${brand}-form-label-error`
                            : `${brand}-form-label`
                        }>
                        <FormattedMessage {...messages.expirationDateLabel} />
                      </span>
                    </div>
                    <div
                      style={{
                        marginRight: 15,
                        width: '100%',
                        display: 'flex',
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}>
                        <div
                          data-recurly="month"
                          className={
                            formErrors.month +
                            ' bg-transparent form-control p-0 border-top-0 border-left-0 border-right-0 rounded-0'
                          }></div>
                        {hasError(formErrors.month) ? (
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            className={`${brand}-error-text`}
                            id="month-error">
                            <FormattedMessage {...messages.monthValidationError} />
                          </Typography>
                        ) : (
                          <div></div>
                        )}
                      </div>
                      <div className="separator">/</div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}>
                        <div
                          data-recurly="year"
                          className={
                            formErrors.year +
                            ' bg-transparent form-control p-0 border-top-0 border-left-0 border-right-0 rounded-0'
                          }></div>
                        {hasError(formErrors.year) ? (
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            id="year-error"
                            className={`${brand}-error-text`}
                            style={isMobile ? { marginRight: 0 } : { marginRight: 23 }}>
                            <FormattedMessage {...messages.yearValidationError} />
                          </Typography>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div style={{ marginLeft: 20 }}>
                    <div>
                      <span className={hasError(formErrors.cvv) ? `${brand}-form-label-error` : `${brand}-form-label`}>
                        <FormattedMessage {...messages.cvvLabel} />
                      </span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      <div
                        data-recurly="cvv"
                        className={
                          formErrors.cvv +
                          ' bg-transparent form-control p-0 border-top-0 border-left-0 border-right-0 rounded-0'
                        }></div>
                      <input type="hidden" id="recurly-token" name="recurly-token" data-recurly="token" />
                      <div>
                        <IconButton
                          aria-label="info"
                          aria-describedby={id}
                          onClick={handleClick}
                          style={{ marginTop: -20 }}>
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </div>
                    </div>
                    {hasError(formErrors.cvv) ? (
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        id="cvv-error"
                        className={`${brand}-error-text`}
                        style={{ marginTop: -7 }}>
                        <FormattedMessage {...messages.cvvValidationError} />
                      </Typography>
                    ) : null}
                  </div>
                </div>

                {props.showPromoSection ? (
                  <div style={{ marginTop: 25 }}>
                    <PromoCode brand={brand}></PromoCode>
                  </div>
                ) : null}
              </div>
            </div>
          </form>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}>
            <div
              style={{
                height: 100,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 10,
              }}>
              <div
                style={{
                  marginBottom: 10,
                }}>
                <FormattedMessage {...messages.cvvToolTip} />
              </div>
              <div>
                <div>
                  <img src={cvvImage} alt="cvv" />
                </div>
              </div>
            </div>
          </Popover>
        </Container>
      )}
    </>
  );
};

export default injectIntl(BillingInformationView);
